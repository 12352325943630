<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-22 13:13:32
 * @LastEditTime: 2020-04-25 10:49:54
 -->
<template>
	<div v-loading="loading" size="none" class="staff" style="padding: 0 20px 0">
		<el-form inline :model="searchForm" class="demo-form-inline" size="small">
			<el-form-item label="姓名">
				<el-input v-model="searchForm.staffName" placeholder="请输入姓名" clearable></el-input>
			</el-form-item>
			<el-form-item label="培训课程">
				<el-select placeholder="请选择培训课程" filterable clearable size="small" v-model="searchForm.trainingCourseId">
					<el-option v-for="item in list2" :key="item.id" :value="item.id" :label="item.trainName"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="生成时间">
				<el-date-picker
					v-model="searchForm.generateStartDate"
					size="small"
					default-time="00:00:00"
					type="date"
					value-format="yyyy-MM-dd HH:mm"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.generateEndDate"
					size="small"
					default-time="23:59:59"
					type="date"
					value-format="yyyy-MM-dd HH:mm"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
			</el-form-item>
			<el-form-item label="已读状态">
				<el-select v-model="searchForm.readStatus" placeholder="请选择" clearable>
					<el-option label="未读" value="0"></el-option>
					<el-option label="已读" value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-button type="primary" size="small" style="margin-left: 5px" icon="el-icon-search" @click="_getList(1)">查询</el-button>
		</el-form>
		<el-table :data="list" stripe>
			<el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
			<el-table-column v-for="(col, i) in columns" :prop="col.prop" :key="i" align="center" :label="col.label">
				<template v-slot="{ row }" v-if="col.prop == 'trainingCourse'">
					<div class="coursePlace">{{ row[col.prop] }}</div>
				</template>
				<template v-slot="{ row }" v-else-if="col.prop == 'generateDate'">
					{{ row.generateDate.slice(0, 10) }}
				</template>
			</el-table-column>
			<el-table-column align="center" label="已读状态">
				<template slot-scope="{ row }">
					{{ row.readStatus == '0' ? '未读' : row.readStatus == '1' ? '已读' : '' }}
				</template>
			</el-table-column>
			<el-table-column align="center" label="操作" fixed="right">
				<template slot-scope="scope">
					<el-button type="text" @click="_toDetail(scope.row)">详情</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			style="margin: 20px 0; text-align: right"
			@size-change="_handleSizeChange"
			@current-change="_handleCurrentChange"
			:current-page="searchForm.pageNo"
			:page-sizes="[20, 50, 100, 200]"
			:page-size="searchForm.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="searchForm.total"
		></el-pagination>
	</div>
</template>
<script>
import { information, trainingName } from '@/api/trainMange.js';
export default {
	data() {
		return {
			loading: false,
			dateRange: '',
			list: [],
			list2: [],
			searchForm: {
				generateStartDate: '',
				generateEndDate: '',
				trainingCourseId: '',
				readStatus: '',
				staffName: '',
				pageNo: 1,
				pageSize: 20,
				total: 0
			},
			columns: [
				{ label: '培训课程', prop: 'trainingCourse' },
				{ label: '姓名', prop: 'staffName' },
				{ label: '工号', prop: 'workNumber' },
				{ label: '部门', prop: 'departmentName' },
				{ label: '岗位', prop: 'jobName' },
				{ label: '考核结果', prop: 'accessScore' },
				{ label: '生成时间', prop: 'generateDate' }
			]
		};
	},
	methods: {
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			this.loading = true;
			information(this.searchForm).then((res) => {
				this.loading = false;
				this.list = res.list;
				this.searchForm.total = res.totalNum;
			});
		},
		_dateRangeChange(val) {
			if (val) {
				this.searchForm.generateStartDate = val[0];
				this.searchForm.generateEndDate = val[1];
			} else {
				this._clear();
			}
		},
		_clear() {
			this.dateRange = '';
			this.searchForm.generateStartDate = '';
			this.searchForm.generateEndDate = '';
		},
		_getNameList() {
			trainingName({}).then((res) => {
				this.list2 = res;
			});
		},
		_toDetail(row) {
			this.$router.push({ name: 'TrainDetail', query: { type: 2, id: row.id, trainingCourseId: row.trainingCourseId, cc: 1 } });
		}
	},
	mounted() {
		this._getNameList();
		this._getList();
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form--inline .el-form-item {
	vertical-align: initial;
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
.coursePlace {
	width: 180px;
	white-space: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
}
</style>
