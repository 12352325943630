<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-28 10:15:15
 * @LastEditTime: 2020-04-29 09:21:41
 -->
<template>
	<div v-loading="loading" size="none" style="padding: 0 20px 0">
		<el-form inline :model="searchForm" class="demo-form-inline" size="small">
			<el-form-item label="姓名">
				<el-input v-model="searchForm.name" placeholder="请输入姓名" clearable style="witdh: 160px"></el-input>
			</el-form-item>
			<el-form-item label="审批类型">
				<el-select v-model="searchForm.subType" clearable placeholder="请选择" size="small" style="witdh: 160px">
					<el-option v-for="(item, index) in typeOptions" :key="index" :label="item.dictName" :value="item.dictId"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="审批结果">
				<el-select v-model="searchForm.approvalStatus" clearable placeholder="请选择" size="small" style="witdh: 160px">
					<el-option value="1" label="通过"></el-option>
					<el-option value="2" label="不通过"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="生成时间">
				<el-date-picker
					v-model="searchForm.createTimeStart"
					size="small"
					default-time="00:00:00"
					type="date"
					value-format="yyyy-MM-dd HH:mm"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
				<span style="margin: 0 5px">-</span>
				<el-date-picker
					v-model="searchForm.createTimeEnd"
					size="small"
					default-time="23:59:59"
					type="date"
					value-format="yyyy-MM-dd HH:mm"
					format="yyyy-MM-dd"
					clearable
				></el-date-picker>
			</el-form-item>
			<el-form-item label="已读状态">
				<el-select v-model="searchForm.readStatus" placeholder="请选择" clearable>
					<el-option label="未读" value="0"></el-option>
					<el-option label="已读" value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0" style="float: right; margin-right: 0"> </el-form-item>
		</el-form>
		<el-table :data="list" stripe>
			<el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
			<el-table-column v-for="(col, i) in columns" :prop="col.prop" :key="i" align="center" :label="col.label" width="150">
				<template v-slot="{ row }" v-if="col.prop == 'updateTime'">
					{{ row.updateTime.slice(0, 10) }}
				</template>
			</el-table-column>
			<el-table-column align="center" label="已读状态">
				<template slot-scope="{ row }">
					{{ row.readStatus == '0' ? '未读' : row.readStatus == '1' ? '已读' : '' }}
				</template>
			</el-table-column>
			<el-table-column align="center" width="130" label="操作">
				<template slot-scope="scope">
					<el-row>
						<el-button type="text" @click="_toDetail(scope.row)">详情</el-button>
						<!-- <el-button type="text">打印</el-button> -->
					</el-row>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination
			style="margin: 20px 0; text-align: right"
			@size-change="_handleSizeChange"
			@current-change="_handleCurrentChange"
			:current-page="searchForm.pageNo"
			:page-sizes="[20, 50, 100, 200]"
			:page-size="searchForm.pageSize"
			layout="total, sizes, prev, pager, next, jumper"
			:total="searchForm.total"
		></el-pagination>
	</div>
</template>
<script>
import { coreCommonMessageList } from '@/api/staffSystem';
export default {
	data() {
		return {
			loading: false,
			dateRange: '',
			list: [],
			list2: [],
			searchForm: {
				createTimeStart: '',
				createTimeEnd: '',
				readStatus: '',
				subType: '',
				name: '',
				approvalStatus: '',
				pageNo: 1,
				pageSize: 20,
				total: 0
			},
			typeOptions: [],
			columns: [
				{ label: '姓名', prop: 'name' },
				{ label: '工号', prop: 'jobNumber' },
				{ label: '部门', prop: 'orgName' },
				{ label: '岗位', prop: 'jobName' },
				{ label: '类型', prop: 'subTypeName' },
				{ label: '状态', prop: 'approvalStatusName' },
				{ label: '生成时间', prop: 'updateTime' }
			]
		};
	},
	methods: {
		_handleSizeChange(val) {
			this.searchForm.pageSize = val;
			this._getList();
		},
		_handleCurrentChange(val) {
			this.searchForm.pageNo = val;
			this._getList();
		},
		_getList(page) {
			page ? (this.searchForm.pageNo = page) : '';
			this.loading = true;
			coreCommonMessageList(this.searchForm).then((res) => {
				this.loading = false;
				this.list = res.list;
				this.searchForm.total = res.totalNum;
			});
		},
		_dateRangeChange(val) {
			if (val) {
				this.searchForm.createTimeStart = val[0];
				this.searchForm.createTimeEnd = val[1];
			} else {
				this._clear();
			}
		},
		_clear() {
			this.dateRange = '';
			this.searchForm.createTimeStart = '';
			this.searchForm.createTimeEnd = '';
		},
		_toDetail(row) {
			this.$router.push({
				path: '/staff/approval_details',
				query: {
					id: row.id,
					readStatus: '1',
					queryType: row.subType,
					approvalTypeName: row.subTypeName
				}
			});
		}
	},
	mounted() {
		this.typeOptions = this.$store.state.app.dict.filter((item) => item.groupId === 'subType');
		this._getList();
	}
};
</script>
<style lang="scss" scoped>
::v-deep.el-form--inline .el-form-item {
	vertical-align: initial;
}
::v-deep.el-form-item__label {
	font-weight: normal;
}
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
