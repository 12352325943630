<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-22 13:26:29
 * @LastEditTime: 2020-04-28 11:12:22
 -->
<template>
    <section style="position:relative">
        <el-tabs v-model="activeName" style="padding: 20px;background-color: #fff;">
            <el-tab-pane :label="`普通信息(${counts.commonCount || 0})`" name="1" lazy>
                <Approval ref="child1"></Approval>
            </el-tab-pane>
            <el-tab-pane :label="`核心人力信息(${counts.coreCount || 0})`" name="2" lazy>
                <Corehuman ref="child2"></Corehuman>
            </el-tab-pane>
            <el-tab-pane :label="`教育培训(${counts.trainCount || 0})`" name="3" lazy>
                <Train ref="child3"></Train>
            </el-tab-pane>
        </el-tabs>
        <el-button type="primary" @click="Allreadover" style="position: absolute;top: 20px;right: 40px;" icon="el-icon-s-comment" size="small">全部已读</el-button>
    </section>
</template>
<script>
import Train from '../trainMange/ccList';
import Approval from '../approvalCCList';
import Corehuman from '../corehumanCCList';
import { coreMessageCount, readAll } from '@/api/staffSystem';
export default {
    components: { Approval, Corehuman, Train },
    data() {
        return {
            activeName: '1',
            counts: ''
        };
    },
    methods: {
        Allreadover() {
            readAll({}).then((res) => {
                if (res.code == 0) {
                    this.$parent.queryBadge();
                    this.$message.success('操作成功');
                }
            });
        },
        _getCount() {
            coreMessageCount({}).then((res) => {
                delete res._responseStatusCode;
                this.counts = res;
            });
        }
    },
    beforeRouteLeave(to, from, next) {
        sessionStorage.setItem(
          `${this.$route.name}Data`,
          JSON.stringify({
            activeName: this.activeName,
            searchForm: this.activeName == '1' ? this.$refs.child1.searchForm : this.activeName == '2' ? this.$refs.child2.searchForm : this.activeName == '2' ? this.$refs.child3.searchForm : {}
          })
        );
        next();
    },
    mounted() {
        const data = JSON.parse(sessionStorage.getItem(`${this.$route.name}Data`));

        if (data) {
            this.activeName = data.activeName;
            this.searchForm = data.searchForm;
        }
        this._getCount();
    },
    watch: {
        activeName: {
            deep: true,
            handler(newVal) {
                this.$refs[`child${newVal}`] && this.$refs[`child${newVal}`]._getList();
                this._getCount();
            }
        }
    }
};
</script>
<style lang="scss" scoped>

</style>
